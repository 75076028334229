import './AppointmentSchedule.less'

import React, { useState } from 'react'

import { useScopedIntl } from '../../../../../hooks'
import { Schedule, ScheduleVisit, createSchedule } from '../../../../../requests'
import { DatacLoading, DatacMessage } from '../../../../common'
import { useRecruitmentStudyDetailsStore } from '../../RecruitmentStudyDetailsStore'
import { AppointmentScheduleConfirmation } from './AppointmentScheduleConfirmation'
import { AppointmentScheduleFirst } from './AppointmentScheduleFirst'
import { AppointmentScheduleList } from './AppointmentScheduleList'
import { AppointmentScheduleSetUp } from './AppointmentScheduleSetUp'
import { AppointmentScheduleVisit } from './AppointmentScheduleVisit'

export interface VisitSlotsCounts {
  created: number
  needed: number
}

interface Props {
  schedules: Schedule[]
  isFetchingSchedules: boolean
  isSettingUpSchedule: boolean
  setIsSettingUpSchedule: (isSettingUpSchedule: boolean) => void
  fetchSchedules: () => void
}

export const AppointmentSchedule: React.FC<Props> = ({
  schedules,
  isFetchingSchedules,
  isSettingUpSchedule,
  setIsSettingUpSchedule,
  fetchSchedules
}) => {
  const intl = useScopedIntl('')
  const intlSchedule = useScopedIntl('recruitment.study.schedules')
  const [nrOfVisitToEdit, setNrOfVisitToEdit] = useState<number>(null)
  const [currentSchedule, setCurrentSchedule] = useState<Schedule>()
  const [slotsCounts, setSlotsCounts] = useState<VisitSlotsCounts[]>([])
  const [showConfirmationPage, setShowConfirmationPage] = useState(false)
  const { study } = useRecruitmentStudyDetailsStore()

  const onScheduleSetUp = (data: Schedule) => {
    setCurrentSchedule(schedule => ({ ...schedule, ...data }))
    setNrOfVisitToEdit(1)
  }

  const goToNextVisit = (currentVisitNumber: number) => {
    if (currentVisitNumber === currentSchedule.visitsCount) {
      setShowConfirmationPage(true)
      return
    }

    setNrOfVisitToEdit(currentVisitNumber + 1)
  }

  const onUpdateScheduleVisit = (visit: ScheduleVisit) => {
    const newSchedule = {
      ...currentSchedule,
      visits: [...(currentSchedule.visits?.filter(v => v.number !== visit.number) || []), visit]
    }

    if (visit.number !== currentSchedule.visitsCount) {
      setCurrentSchedule(newSchedule)
      goToNextVisit(visit.number)
      return
    }

    createSchedule(
      { ...newSchedule, studyId: study.id },
      {
        onSuccess: id => {
          setCurrentSchedule({ id, ...newSchedule })
          goToNextVisit(visit.number)
        },
        onOverlappingError: () =>
          DatacMessage.error(intlSchedule('overlapping.title'), intlSchedule('overlapping.description')),
        onRequestError: code => DatacMessage.genericError(intl, code)
      }
    )
  }

  const onUpdateSlotCounts = (created: number, needed: number) => {
    setSlotsCounts(c => {
      const newSlotsCount = [...c]
      newSlotsCount[nrOfVisitToEdit - 1] = { created, needed }
      return newSlotsCount
    })
  }

  const onClose = (createNew: boolean) => {
    setIsSettingUpSchedule(!!createNew)
    setNrOfVisitToEdit(null)
    setCurrentSchedule(null)
    setShowConfirmationPage(false)
    fetchSchedules()
  }

  const onGoBack = () => {
    setNrOfVisitToEdit(nrOfVisitToEdit - 1)
  }

  const showCreateScheduleMessage = !isSettingUpSchedule && !schedules.length && !isFetchingSchedules
  return (
    <div className="appointment-schedule">
      {isFetchingSchedules && <DatacLoading isLoading />}
      {showCreateScheduleMessage && <AppointmentScheduleFirst onCreateClick={() => setIsSettingUpSchedule(true)} />}
      {isSettingUpSchedule && (
        <AppointmentScheduleSetUp
          onClose={() => onClose(false)}
          onSubmit={onScheduleSetUp}
          schedule={currentSchedule}
        />
      )}
      {!!nrOfVisitToEdit && (
        <AppointmentScheduleVisit
          onClose={() => onClose(false)}
          onUpdateScheduleVisit={onUpdateScheduleVisit}
          schedule={currentSchedule}
          visitNumber={nrOfVisitToEdit}
          onGoBack={onGoBack}
          setNrOfVisitToEdit={setNrOfVisitToEdit}
          onUpdateSlotCounts={onUpdateSlotCounts}
          slotsCounts={slotsCounts}
        />
      )}
      {showConfirmationPage && (
        <AppointmentScheduleConfirmation
          onClose={() => onClose(false)}
          onCreateNew={() => onClose(true)}
          schedule={currentSchedule}
          studyId={study.id}
        />
      )}
      {!!schedules?.length && <AppointmentScheduleList schedules={schedules} fetchSchedules={() => fetchSchedules()} />}
    </div>
  )
}
