import { RouteComponentProps } from '@gatsbyjs/reach-router'
import { Button, DatePicker, Form, Input, InputNumber, RefSelectProps } from 'antd'
import React, { useEffect, useRef, useState } from 'react'

import { useScopedIntl } from '../../../../../hooks'
import {
  CenterData,
  RecruitmentStudy,
  RecruitmentStudyStatus,
  WashOutPeriodType,
  fetchZones,
  updateRecruitmentStudy
} from '../../../../../requests'
import { enumToOptions } from '../../../../../utils'
import {
  maxParagraphLength,
  maxTitleLengthExtraLong,
  maxTitleLengthMedium,
  validateMinItems,
  validateRequired,
  validateStudyReference
} from '../../../../../validation'
import {
  DatacFormItem,
  DatacIcon,
  DatacMessage,
  DatacModal,
  DatacOption,
  DatacSelect,
  DatacSettingsFormItem,
  DatacStatusSelect,
  DatacTitle
} from '../../../../common'
import { useRecruitmentStudyDetailsStore } from '../../RecruitmentStudyDetailsStore'

export const RecruitmentStudySettingsGeneral: React.FC<RouteComponentProps> = () => {
  const intlFields = useScopedIntl('recruitment.study.fields')
  const intlFieldsPeriod = useScopedIntl('recruitment.study.fields.duration_unit')
  const intlMenu = useScopedIntl('recruitment.study.settings.menu')
  const [isEditingOn, setIsEditingOn] = useState(false)
  const [currentEnteredZone, setCurrentEnteredZone] = useState('')
  const zoneRef = useRef<RefSelectProps>()
  const [zoneOptions, setZoneOptions] = useState<DatacOption[]>([])
  const [form] = Form.useForm()
  const { study, setStudy, centers } = useRecruitmentStudyDetailsStore()
  const intl = useScopedIntl('')
  const [isReferenceTaken, setIsReferenceTaken] = useState(false)
  const [centersError, setCentersError] = useState<string>(null)
  const [centersOptions, setCentersOptions] = useState<DatacOption[]>([])
  const [isConfirmSaveVisible, setIsConfirmSaveVisible] = useState(false)

  useEffect(() => {
    if (!study || !centers) return

    resetFields()
    setCentersOptions(
      centers.map((center: CenterData) => ({
        value: center.id,
        label: center.abbreviation,
        sublabel: center.name
      }))
    )
  }, [study, centers])

  useEffect(() => {
    fetchZones({
      onSuccess: zones => setZoneOptions(zones.map(zone => ({ value: zone, label: zone }))),
      onRequestError: () => DatacMessage.genericError(intl)
    })
  }, [])

  const statusOptions = {
    [RecruitmentStudyStatus.Draft]: {
      value: RecruitmentStudyStatus.Draft,
      label: intl('studies.status.draft'),
      className: 'recruitment-study-settings__content__status-icon--draft'
    },
    [RecruitmentStudyStatus.Recruiting]: {
      value: RecruitmentStudyStatus.Recruiting,
      label: intl('studies.status.recruiting'),
      className: 'recruitment-study-settings__content__status-icon--live'
    },
    [RecruitmentStudyStatus.Ended]: {
      value: RecruitmentStudyStatus.Ended,
      label: intl('studies.status.ended'),
      className: 'recruitment-study-settings__content__status-icon--ended'
    },
    [RecruitmentStudyStatus.Archived]: {
      value: RecruitmentStudyStatus.Archived,
      label: intl('studies.status.archived'),
      className: 'recruitment-study-settings__content__status-icon--archived'
    }
  }

  const statusTransitionOptions = {
    [RecruitmentStudyStatus.Draft]: [
      statusOptions[RecruitmentStudyStatus.Draft],
      statusOptions[RecruitmentStudyStatus.Recruiting],
      statusOptions[RecruitmentStudyStatus.Ended]
    ],
    [RecruitmentStudyStatus.Recruiting]: [
      statusOptions[RecruitmentStudyStatus.Recruiting],
      statusOptions[RecruitmentStudyStatus.Archived],
      statusOptions[RecruitmentStudyStatus.Ended]
    ],
    [RecruitmentStudyStatus.Ended]: [
      statusOptions[RecruitmentStudyStatus.Ended],
      statusOptions[RecruitmentStudyStatus.Archived]
    ],
    [RecruitmentStudyStatus.Archived]: [statusOptions[RecruitmentStudyStatus.Archived]]
  }

  const inputDisabled = !isEditingOn

  const resetFields = () => {
    form.setFieldsValue({
      name: study.name,
      reference: study.reference,
      recruiterName: study.recruiterName,
      target: study.target,
      centerIds: study.centerIds,
      status: study.status,
      targetDate: study.targetDate,
      expectedDuration: study.expectedDuration,
      expectedDurationType: study.expectedDurationType || WashOutPeriodType.Days,
      studyZones: study.studyZones
    })
  }

  const onCancel = () => {
    setIsReferenceTaken(false)
    setCentersError(null)
    setIsEditingOn(false)
    resetFields()
    form.validateFields()
  }

  const onSave = () => {
    const newStatus = form.getFieldValue('status')
    if (newStatus === RecruitmentStudyStatus.Recruiting && study.status !== RecruitmentStudyStatus.Recruiting) {
      setIsConfirmSaveVisible(true)
      return
    }
    form.submit()
  }

  const onEdit = () => {
    setIsEditingOn(true)
  }

  const onSubmit = (data: RecruitmentStudy) => {
    updateRecruitmentStudy(
      { ...data, studyId: study.id },
      {
        onSuccess: study => {
          setIsEditingOn(false)
          setStudy(study)
        },
        onRequestError: code => DatacMessage.genericError(intl, code),
        onReferenceTaken: () => setIsReferenceTaken(true),
        onTooManyCenters: () => setCentersError(intlFields('centers.validation.too_many')),
        onCenterCannotBeRemoved: () => setCentersError(intlFields('centers.validation.cannot_remove'))
      }
    )
  }

  const onReferenceChange = () => {
    if (isReferenceTaken) setIsReferenceTaken(false)
  }
  const onCentersChange = () => {
    if (centersError) setCentersError(null)
  }

  const onZoneSearch = (searchPhrase: string) => {
    setCurrentEnteredZone(searchPhrase)
  }

  const addNewZone = (zone: string) => {
    const selectedZones = form.getFieldValue('studyZones') || []
    form.setFieldsValue({ studyZones: [...selectedZones, zone] })
  }

  const onZoneKeyPressed = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (
      e.key !== 'Enter' ||
      !currentEnteredZone.trim() ||
      zoneOptions.find(({ value }) => value === currentEnteredZone)
    ) {
      return
    }

    setZoneOptions(currentAvailableZones => [
      ...currentAvailableZones,
      { value: currentEnteredZone, label: currentEnteredZone }
    ])
    addNewZone(currentEnteredZone)
    onZoneSearch('')
    setCurrentEnteredZone('')
    zoneRef.current.blur()
  }

  return (
    <div className="recruitment-study-settings__content__body recruitment-study-settings__content__body--with-header">
      <div className="recruitment-study-settings__content__header">
        <div className="recruitment-study-settings__content__header__content">
          <DatacTitle type="h1">{intlMenu('general')}</DatacTitle>
        </div>
        <div className="recruitment-study-settings__content__form-controls">
          {isEditingOn ? (
            <>
              <Button size="large" type="default" onClick={onCancel}>
                {intl('common.cancel')}
              </Button>
              <Button size="large" type="primary" onClick={onSave}>
                {intl('common.save')}
              </Button>
            </>
          ) : (
            <Button size="large" type="primary" onClick={onEdit}>
              {intl('common.edit')}
            </Button>
          )}
        </div>
      </div>
      <div className="recruitment-study-settings__content__form">
        <Form form={form} onFinish={data => onSubmit(data as RecruitmentStudy)}>
          <DatacTitle type="h3">{intlFields('study_settings')}</DatacTitle>
          <DatacSettingsFormItem
            name="reference"
            label={intlFields('reference.label')}
            validate={[
              validateRequired(intl('common.required')),
              validateStudyReference(intlFields('reference.validation.characters'))
            ]}
            error={isReferenceTaken ? intlFields('reference.validation.taken') : undefined}
          >
            <Input
              disabled={inputDisabled}
              size="large"
              placeholder={intlFields('reference.placeholder')}
              maxLength={maxTitleLengthMedium}
              onChange={onReferenceChange}
            />
          </DatacSettingsFormItem>

          <DatacSettingsFormItem
            name="name"
            label={intlFields('name.label')}
            validate={validateRequired(intl('common.required'))}
          >
            <Input
              disabled={inputDisabled}
              size="large"
              placeholder={intlFields('name.placeholder')}
              maxLength={maxTitleLengthExtraLong}
            />
          </DatacSettingsFormItem>

          <DatacSettingsFormItem
            name="recruiterName"
            label={intlFields('recruiter.label')}
            validate={validateRequired(intl('common.required'))}
          >
            <Input
              disabled={inputDisabled}
              size="large"
              placeholder={intlFields('recruiter.placeholder')}
              maxLength={maxParagraphLength}
            />
          </DatacSettingsFormItem>

          <DatacSettingsFormItem
            name="target"
            label={intlFields('target_number.label')}
            validate={validateRequired(intl('common.required'))}
          >
            <InputNumber
              disabled={inputDisabled}
              size="large"
              placeholder={intlFields('target_number.placeholder')}
              precision={0}
              min={1}
            />
          </DatacSettingsFormItem>

          <DatacSettingsFormItem
            name="centerIds"
            label={intlFields('centers.label')}
            validate={validateMinItems(intl('common.required'), 1)}
            error={centersError}
          >
            <DatacSelect
              showSearch
              mode="multiple"
              disabled={inputDisabled}
              placeholder={intlFields('centers.placeholder')}
              options={centersOptions}
              onChange={onCentersChange}
            />
          </DatacSettingsFormItem>

          <DatacSettingsFormItem
            name="status"
            label={intlFields('status.label')}
            validate={validateRequired(intl('common.required'))}
          >
            <DatacStatusSelect
              disabled={!isEditingOn}
              statusTransitionOptions={statusTransitionOptions[study.status as RecruitmentStudyStatus]}
            />
          </DatacSettingsFormItem>

          <hr />

          <DatacTitle type="h3">{intlFields('study_details')}</DatacTitle>

          <DatacSettingsFormItem name="targetDate" label={intlFields('target_date.label')}>
            <DatePicker
              disabled={inputDisabled}
              size="large"
              placeholder={intlFields('target_date.placeholder')}
              suffixIcon={<DatacIcon name="calendar" type="blue" />}
              onChange={value => {
                if (!value) {
                  form.setFieldsValue({ targetDate: null })
                }
              }}
            />
          </DatacSettingsFormItem>

          <DatacSettingsFormItem
            label={intlFields('expected_duration.label')}
            className="recruitment-study-settings__wash-out-period"
            addonAfter={
              <Form.Item noStyle name="expectedDurationType">
                <DatacSelect disabled={inputDisabled} options={enumToOptions(WashOutPeriodType, intlFieldsPeriod)} />
              </Form.Item>
            }
          >
            <DatacFormItem noStyle name="expectedDuration">
              <InputNumber
                disabled={inputDisabled}
                size="large"
                placeholder={intlFields('expected_duration.placeholder')}
                min={0}
                precision={0}
                onKeyDown={e => {
                  if (e.key === '.') {
                    e.preventDefault()
                  }
                }}
              />
            </DatacFormItem>
          </DatacSettingsFormItem>

          <DatacSettingsFormItem name="studyZones" label={intlFields('study_zones.label')}>
            <DatacSelect
              disabled={inputDisabled}
              size="large"
              placeholder={intlFields('study_zones.placeholder')}
              showSearch
              options={zoneOptions}
              onInputKeyDown={onZoneKeyPressed}
              onSearch={onZoneSearch}
              defaultActiveFirstOption={false}
              selectRef={zoneRef}
              mode="multiple"
            />
          </DatacSettingsFormItem>
        </Form>
      </div>
      <DatacModal
        title={intlFields('confirm_status_change.title')}
        isOpened={isConfirmSaveVisible}
        onClose={() => setIsConfirmSaveVisible(false)}
        onSubmit={() => {
          form.submit()
          setIsConfirmSaveVisible(false)
        }}
        className="study-settings-general__confirm-modal"
        submitLabel={intl('common.confirm')}
      >
        <div className="study-settings-general__confirm-modal-body">
          {intlFields('confirm_status_change.description')}
        </div>
      </DatacModal>
    </div>
  )
}
