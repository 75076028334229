import './ScheduleListSlotsBooking.less'

import { Popover } from 'antd'
import { debounce } from 'lodash'
import React, { useEffect, useState } from 'react'

import { useScopedIntl } from '../../../../../../../../hooks'
import {
  BookedVisitStatus,
  CenterData,
  ParticipantStatus,
  RecordStatus,
  ScheduleSlot,
  ScheduleSlotSubject,
  ScheduleVisit,
  SorterOrder,
  fetchParticipants
} from '../../../../../../../../requests'
import {
  DatacAvatar,
  DatacDropdownMenu,
  DatacIcon,
  DatacMessage,
  DatacRecordStatusTag,
  DatacSelect,
  DatacTitle,
  DatacToggleExpand
} from '../../../../../../../common'
import { useRecruitmentStudyDetailsStore } from '../../../../../RecruitmentStudyDetailsStore'

interface ScheduleListSlotsBookingProps {
  slot: ScheduleSlot
  visit: ScheduleVisit
  onAddSubject: (subject: ScheduleSlotSubject) => void
  onRemoveSubject: (subjectId: string) => void
  selectionDisabled: boolean
}

export const ScheduleListSlotsBooking: React.FC<ScheduleListSlotsBookingProps> = ({
  slot,
  visit,
  children,
  onAddSubject,
  onRemoveSubject,
  selectionDisabled
}) => {
  const intlCalendar = useScopedIntl('calendar')
  const intlStatus = useScopedIntl('status')
  const intl = useScopedIntl('')
  const intlSubjects = useScopedIntl('calendar.event.subjects')
  const [isPopoverVisible, setIsPopoverVisible] = useState(false)
  const [isShowingSubjects, setIsShowingSubjects] = useState(false)
  const { study, centers } = useRecruitmentStudyDetailsStore()
  const [currentCenter, setCurrentCenter] = useState<CenterData>()
  const [isAddingSubject, setIsAddingSubject] = useState(false)
  const [subjects, setSubjects] = useState<ScheduleSlotSubject[]>([])
  const [isFetchingSubjectList, setIsFetchingSubjectList] = useState(false)

  useEffect(() => {
    setCurrentCenter(centers.find((c: CenterData) => c.id === String(visit?.centerId)))
  }, [visit])

  useEffect(() => {
    if (!isAddingSubject) return

    setSubjects([])
  }, [isAddingSubject])

  const onSearchSubjects = debounce((search = '') => {
    setIsFetchingSubjectList(true)
    fetchParticipants(
      {
        studyId: study.id,
        options: {
          search,
          filters: {
            status: [
              ParticipantStatus.Prospect,
              ParticipantStatus.Contacted,
              ParticipantStatus.Interested,
              ParticipantStatus.Qualified
            ]
          },
          limit: 25,
          offset: 0,
          sorter: { order: SorterOrder.Descend, field: 'firstName' }
        }
      },
      {
        onSuccess: ({ participants }) => {
          setSubjects(
            participants
              .map(({ datacaptId, firstName, lastName }) => ({
                datacaptId,
                name: `${firstName || ''} ${lastName || ''}`,
                status: BookedVisitStatus.Scheduled // when we add subject successfully it will be scheduled by default
              }))
              .filter(s => !slot.subjects.find(c => c.datacaptId === s.datacaptId))
          )
          setIsFetchingSubjectList(false)
        },
        onRequestError: code => {
          DatacMessage.genericError(intl, code)
          setIsFetchingSubjectList(false)
        }
      }
    )
  }, 500)

  const subjectsCount = slot.subjects?.length || 0

  const addSubject = (subjectId: string) => {
    setIsAddingSubject(false)
    setIsShowingSubjects(true)
    onAddSubject(subjects.find(s => s.datacaptId === subjectId))
  }

  return (
    <Popover
      content={
        !selectionDisabled && (
          <div className="schedule-list-slots-booking">
            <div className="schedule-list-slots-booking__header">
              <DatacTitle size="small">{intlCalendar('booking')}</DatacTitle>
              <div className="schedule-list-slots-booking__header__actions">
                <DatacIcon name="x" onClick={() => setIsPopoverVisible(false)} />
              </div>
            </div>
            <div className="schedule-list-slots-booking__row">
              <DatacIcon name="calendar" raw />
              <div>
                {slot.date.format('D MMM YYYY')}
                <span className="schedule-list-slots-booking__row__info"> ({slot.date.format('dddd')})</span>
              </div>
            </div>
            <div className="schedule-list-slots-booking__row">
              <DatacIcon name="clock" raw />
              <div>{slot.startTime}</div>
            </div>
            {currentCenter?.name && (
              <div className="schedule-list-slots-booking__row">
                <DatacIcon name="mapPin" raw />
                <div>
                  {currentCenter.name}
                  <span className="schedule-list-slots-booking__row__info"> ({currentCenter.abbreviation})</span>
                </div>
              </div>
            )}
            <div className="schedule-list-slots-booking__row">
              <DatacIcon name="users" raw />
              <div className="schedule-list-slots-booking__subjects">
                <div className="schedule-list-slots-booking__subjects__header">
                  <div
                    className="schedule-list-slots-booking__subjects__header__toggle"
                    onClick={() => setIsShowingSubjects(!isShowingSubjects)}
                  >
                    {subjectsCount}/{visit?.capacity} {intlSubjects('label')}
                    {!!subjectsCount && (
                      <DatacToggleExpand
                        type="transparent"
                        isCollapsed={!isShowingSubjects}
                        onToggle={setIsShowingSubjects}
                      />
                    )}
                  </div>
                  {subjectsCount < visit?.capacity && (
                    <div
                      className="schedule-list-slots-booking__subjects__header__add"
                      onClick={() => setIsAddingSubject(!isAddingSubject)}
                    >
                      <DatacIcon name="plus" raw />
                      {intlSubjects('add')}
                    </div>
                  )}
                </div>
                {isAddingSubject && (
                  <DatacSelect
                    showSearch
                    options={subjects.map(s => ({ value: s.datacaptId, label: s.name, sublabel: s.datacaptId }))}
                    placeholder={intlCalendar('appointment.subjects.placeholder')}
                    onSearch={onSearchSubjects}
                    loading={isFetchingSubjectList}
                    onChange={addSubject}
                  />
                )}
                <div className="schedule-list-slots-booking__subjects__list">
                  {isShowingSubjects &&
                    slot.subjects?.map(s => (
                      <div key={s.datacaptId} className="schedule-list-slots-booking__subjects__list__subject">
                        <DatacAvatar photoThumbnail={s.thumbnail} fullName={s.name} />
                        <div className="schedule-list-slots-booking__subjects__list__subject__name">
                          <div className="schedule-list-slots-booking__subjects__list__subject__name__label">
                            {s.name}
                          </div>
                          <div className="schedule-list-slots-booking__subjects__list__subject__name__id">
                            {s.datacaptId}
                          </div>
                        </div>
                        <DatacRecordStatusTag
                          status={s.status === BookedVisitStatus.Scheduled ? RecordStatus.InProgress : s.status}
                          label={intlStatus(s.status.toLowerCase())}
                          coloredBackground
                        />
                        <DatacDropdownMenu
                          options={[
                            {
                              type: 'delete',
                              label: 'common.delete',
                              icon: 'trash'
                            }
                          ]}
                          onClick={() => onRemoveSubject(s.datacaptId)}
                        />
                      </div>
                    ))}
                </div>
              </div>
            </div>
          </div>
        )
      }
      trigger="click"
      placement="leftTop"
      open={isPopoverVisible}
      onOpenChange={setIsPopoverVisible}
    >
      {children}
    </Popover>
  )
}
