import './NewRecruitmentStudy.less'

import { Button, Form, Input, InputNumber } from 'antd'
import { navigate } from 'gatsby-plugin-react-intl'
import React, { useEffect, useState } from 'react'

import { useScopedIntl } from '../../../../hooks'
import { CenterData, RecruitmentStudy, createRecruitmentStudy } from '../../../../requests'
import { routes } from '../../../../routes'
import {
  maxTitleLengthExtraLong,
  maxTitleLengthLong,
  maxTitleLengthMedium,
  validateRequired,
  validateStudyReference
} from '../../../../validation'
import { DatacFormItem, DatacIcon, DatacMessage, DatacModal, DatacOption, DatacSelect } from '../../../common'

export const NewRecruitmentStudy: React.VFC<{ centers: CenterData[] }> = ({ centers }) => {
  const intlNewStudy = useScopedIntl('recruitment.new_study')
  const intlFields = useScopedIntl('recruitment.study.fields')
  const [centersOptions, setCentersOptions] = useState<DatacOption[]>([])
  const intl = useScopedIntl('')
  const [isReferenceTaken, setIsReferenceTaken] = useState(false)
  const [tooManyCenters, setTooManyCenters] = useState(false)
  const [formInstance] = Form.useForm()
  const [isModalOpened, setIsModalOpened] = useState(false)

  useEffect(() => {
    setCentersOptions(centers.map(({ id, abbreviation, name }) => ({ value: id, label: abbreviation, sublabel: name })))
  }, [centers])

  const onSubmit = (data: RecruitmentStudy) =>
    createRecruitmentStudy(data, {
      onSuccess: ({ uuid }) => navigate(routes.recruitmentStudy(uuid)),
      onRequestError: code => DatacMessage.genericError(intl, code),
      onReferenceTaken: () => setIsReferenceTaken(true),
      onTooManyCenters: () => setTooManyCenters(true)
    })

  const onReferenceChange = () => {
    if (isReferenceTaken) setIsReferenceTaken(false)
  }
  const onCentersChange = () => {
    if (tooManyCenters) setTooManyCenters(false)
  }

  return (
    <>
      <Button
        className="new-recruitment-study__button"
        type="primary"
        onClick={() => setIsModalOpened(true)}
        size="large"
      >
        <DatacIcon name="plus" type="white" size="big" /> {intlNewStudy('title')}
      </Button>
      <DatacModal
        className="new-recruitment-study__modal"
        isOpened={isModalOpened}
        onClose={() => setIsModalOpened(false)}
        onSubmit={() => formInstance.submit()}
        title={intlNewStudy('title')}
      >
        <Form
          form={formInstance}
          name="newStudyForm"
          layout="vertical"
          onFinish={data => onSubmit(data as RecruitmentStudy)}
        >
          <DatacFormItem
            label={intlFields('name.label')}
            name="name"
            validate={validateRequired(intl('common.required'))}
          >
            <Input size="large" placeholder={intlFields('name.placeholder')} maxLength={maxTitleLengthExtraLong} />
          </DatacFormItem>

          <DatacFormItem
            label={intlFields('reference.label')}
            name="reference"
            validate={[
              validateRequired(intl('common.required')),
              validateStudyReference(intlFields('reference.validation.characters'))
            ]}
            error={isReferenceTaken ? intlFields('reference.validation.taken') : undefined}
          >
            <Input
              size="large"
              placeholder={intlFields('reference.placeholder')}
              maxLength={maxTitleLengthMedium}
              onChange={onReferenceChange}
            />
          </DatacFormItem>

          <DatacFormItem
            label={intlFields('centers.label')}
            name="centerIds"
            validate={validateRequired(intl('common.required'))}
            error={tooManyCenters ? intlFields('centers.validation.too_many') : undefined}
          >
            <DatacSelect
              showSearch
              mode="multiple"
              options={centersOptions}
              placeholder={intlFields('centers.placeholder')}
              onChange={onCentersChange}
            />
          </DatacFormItem>

          <DatacFormItem
            label={intlFields('recruiter.label')}
            name="recruiterName"
            validate={validateRequired(intl('common.required'))}
          >
            <Input size="large" placeholder={intlFields('recruiter.placeholder')} maxLength={maxTitleLengthLong} />
          </DatacFormItem>

          <DatacFormItem
            label={intlFields('target_number.label')}
            name="target"
            validate={validateRequired(intl('common.required'))}
          >
            <InputNumber size="large" placeholder={intlFields('target_number.placeholder')} precision={0} min={1} />
          </DatacFormItem>
        </Form>
      </DatacModal>
    </>
  )
}
